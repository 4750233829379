import React, { createContext, useContext, useState, useEffect } from 'react';
import { signIn, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  const checkUser = async () => {
    try {
      setLoading(true);
      const currentUser = await getCurrentUser();
      if (currentUser) {
        const session = await fetchAuthSession();
        setUser({ ...currentUser, session });
        return true;
      }
      setUser(null);
      return false;
    } catch (error) {
      console.log('No authenticated user');
      setUser(null);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkUser();
    const hubListener = (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          checkUser();
          break;
        case 'signedOut':
          setUser(null);
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', hubListener);
    return () => Hub.remove('auth', hubListener);
  }, []);

  const login = async (username, password) => {
    setLoading(true);
    setAuthError(null);

    try {
      // Attempt sign in with simplified input
      const { nextStep } = await signIn({
        username: username,
        password: password,
      });

      console.log('Sign in response:', nextStep);

      // Check user immediately after sign in
      await checkUser();
      return true;

    } catch (error) {
      console.error('Login error:', error);
      let errorMessage;

      switch (error.name) {
        case 'NotAuthorizedException':
          errorMessage = 'Incorrect email or password';
          break;
        case 'UserNotConfirmedException':
          errorMessage = 'Please verify your email address';
          break;
        case 'PasswordResetRequiredException':
          errorMessage = 'You need to reset your password';
          break;
        case 'UserNotFoundException':
          errorMessage = 'No account found with this email';
          break;
        default:
          errorMessage = 'Sign in was not successful. Please try again.';
      }

      setAuthError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await signOut();
      setUser(null);
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const value = {
    user,
    loading,
    login,
    logout,
    checkUser,
    authError,
    isAuthenticated: !!user
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;