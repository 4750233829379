import React, { useState } from 'react';
import { Shield, Save, X } from 'lucide-react';

const CreateRole = () => {
  const [roleName, setRoleName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const availablePermissions = [
    { id: 1, name: 'User Management', description: 'Create, edit, and delete users' },
    { id: 2, name: 'Content Management', description: 'Manage content and assets' },
    { id: 3, name: 'Report Access', description: 'View and download reports' },
    { id: 4, name: 'System Settings', description: 'Configure system preferences' },
    { id: 5, name: 'Billing Access', description: 'Manage billing and subscriptions' }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle role creation logic here
    console.log({ roleName, description, selectedPermissions });
  };

  const togglePermission = (permissionId) => {
    setSelectedPermissions(prev => 
      prev.includes(permissionId)
        ? prev.filter(id => id !== permissionId)
        : [...prev, permissionId]
    );
  };

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex items-center mb-6">
          <Shield className="w-6 h-6 text-blue-500 mr-3" />
          <h1 className="text-2xl font-semibold text-slate-200">Create New Role</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="roleName" className="block text-sm font-medium text-slate-300 mb-1">
                Role Name
              </label>
              <input
                id="roleName"
                type="text"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                className="w-full px-3 py-2 bg-slate-700 border border-slate-600 rounded-lg
                         text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2
                         focus:ring-blue-500 focus:border-transparent"
                placeholder="Enter role name"
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-slate-300 mb-1">
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="w-full px-3 py-2 bg-slate-700 border border-slate-600 rounded-lg
                         text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2
                         focus:ring-blue-500 focus:border-transparent"
                placeholder="Describe the role's purpose"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-300 mb-3">
                Permissions
              </label>
              <div className="space-y-3">
                {availablePermissions.map(permission => (
                  <div
                    key={permission.id}
                    className="flex items-center space-x-3 p-3 rounded-lg border
                             border-slate-600 bg-slate-700/50 cursor-pointer
                             hover:bg-slate-700 transition-colors"
                    onClick={() => togglePermission(permission.id)}
                  >
                    <input
                      type="checkbox"
                      checked={selectedPermissions.includes(permission.id)}
                      onChange={() => {}}
                      className="h-4 w-4 rounded border-slate-300 text-blue-600
                               focus:ring-blue-500 bg-slate-600"
                    />
                    <div>
                      <h3 className="text-sm font-medium text-slate-200">{permission.name}</h3>
                      <p className="text-sm text-slate-400">{permission.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              type="submit"
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg
                       hover:bg-blue-700 transition-colors"
            >
              <Save className="w-4 h-4 mr-2" />
              Create Role
            </button>
            <button
              type="button"
              className="flex items-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg
                       hover:bg-slate-600 transition-colors"
            >
              <X className="w-4 h-4 mr-2" />
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRole;