// src/pages/users/UserGroups.js
import React from 'react';
import { Users, Plus, Settings, UserPlus, ChevronRight, Shield } from 'lucide-react';

const UserGroups = () => {
  const groups = [
    {
      id: 1,
      name: 'Administrators',
      members: 5,
      description: 'Full system access and management capabilities',
      permissions: ['Full Access', 'User Management', 'System Settings'],
      type: 'system'
    },
    {
      id: 2,
      name: 'Operations Team',
      members: 12,
      description: 'Access to operational tools and reports',
      permissions: ['Operations Dashboard', 'Report Generation', 'Task Management'],
      type: 'department'
    },
    {
      id: 3,
      name: 'Logistics Staff',
      members: 25,
      description: 'Warehouse and shipping management access',
      permissions: ['Inventory Management', 'Shipping Tools', 'Basic Reports'],
      type: 'department'
    }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">User Groups</h2>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors gap-2">
            <Plus className="w-4 h-4" />
            Create Group
          </button>
        </div>

        <div className="space-y-6">
          {groups.map((group) => (
            <div key={group.id} className="bg-slate-700 rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-start justify-between">
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <Users className="w-5 h-5 text-blue-400" />
                      <h3 className="text-lg font-medium text-slate-200">{group.name}</h3>
                      <span className={`px-2 py-1 text-xs rounded-full ${
                        group.type === 'system' 
                          ? 'bg-purple-900/50 text-purple-400' 
                          : 'bg-blue-900/50 text-blue-400'
                      }`}>
                        {group.type}
                      </span>
                    </div>
                    <p className="text-slate-400 mt-2">{group.description}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button className="p-2 hover:bg-slate-600 rounded-lg transition-colors">
                      <UserPlus className="w-5 h-5 text-slate-400" />
                    </button>
                    <button className="p-2 hover:bg-slate-600 rounded-lg transition-colors">
                      <Settings className="w-5 h-5 text-slate-400" />
                    </button>
                  </div>
                </div>

                <div className="mt-4 flex items-center text-slate-400">
                  <Users className="w-4 h-4 mr-2" />
                  <span>{group.members} members</span>
                </div>

                <div className="mt-4">
                  <div className="text-sm text-slate-400 mb-2">Permissions:</div>
                  <div className="flex flex-wrap gap-2">
                    {group.permissions.map((permission, index) => (
                      <span 
                        key={index}
                        className="flex items-center px-2 py-1 bg-slate-600 rounded-md text-xs text-slate-300"
                      >
                        <Shield className="w-3 h-3 mr-1" />
                        {permission}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="bg-slate-800 border-t border-slate-600 p-4">
                <button className="w-full flex items-center justify-center text-blue-400 
                                 hover:text-blue-300 text-sm gap-1">
                  View Details
                  <ChevronRight className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Empty State */}
        {groups.length === 0 && (
          <div className="text-center py-12">
            <Users className="w-12 h-12 text-slate-600 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-slate-400 mb-2">No Groups Created</h3>
            <p className="text-slate-500">Create a group to manage user permissions</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserGroups;