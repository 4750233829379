// src/pages/profile/ProfileNotifications.js
import React from 'react';
import { Bell, Check, Ban, Clock, Filter, ArrowUpDown } from 'lucide-react';

const ProfileNotifications = () => {
  const notifications = [
    {
      id: 1,
      type: 'mention',
      title: 'You were mentioned in a comment',
      content: '@John Doe left a comment on shipping report',
      time: '10 minutes ago',
      read: false,
      priority: 'high'
    },
    {
      id: 2,
      type: 'task',
      title: 'Task assigned to you',
      content: 'Review Q4 inventory reports',
      time: '1 hour ago',
      read: false,
      priority: 'medium'
    },
    {
      id: 3,
      type: 'system',
      title: 'System Maintenance',
      content: 'Scheduled maintenance in 2 days',
      time: '2 hours ago',
      read: true,
      priority: 'low'
    }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Notification Settings</h2>
          <div className="flex gap-3">
            <button className="flex items-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg 
                             hover:bg-slate-600 transition-colors gap-2">
              <Filter className="w-4 h-4" />
              Filter
            </button>
            <button className="flex items-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg 
                             hover:bg-slate-600 transition-colors gap-2">
              <ArrowUpDown className="w-4 h-4" />
              Sort
            </button>
          </div>
        </div>

        {/* Notification Preferences */}
        <div className="bg-slate-700 rounded-lg p-6 mb-6">
          <h3 className="text-lg font-medium text-slate-200 mb-4">Delivery Preferences</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {['Email', 'Push', 'SMS'].map((method) => (
              <div key={method} className="bg-slate-600 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <span className="text-slate-200">{method} Notifications</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" defaultChecked />
                    <div className="w-11 h-6 bg-slate-500 rounded-full peer peer-checked:after:translate-x-full 
                                 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 
                                 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 
                                 after:transition-all peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Recent Notifications */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-slate-200 mb-4">Recent Notifications</h3>
          {notifications.map((notification) => (
            <div 
              key={notification.id} 
              className={`bg-slate-700 rounded-lg p-4 ${
                !notification.read ? 'border-l-4 border-blue-500' : ''
              }`}
            >
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <h4 className="text-slate-200 font-medium">{notification.title}</h4>
                    <span className={`px-2 py-1 text-xs rounded-full ${
                      notification.priority === 'high' 
                        ? 'bg-red-900/50 text-red-400' 
                        : notification.priority === 'medium'
                        ? 'bg-yellow-900/50 text-yellow-400'
                        : 'bg-green-900/50 text-green-400'
                    }`}>
                      {notification.priority}
                    </span>
                  </div>
                  <p className="text-slate-400 text-sm mt-1">{notification.content}</p>
                  <span className="text-slate-500 text-xs mt-2 flex items-center gap-1">
                    <Clock className="w-3 h-3" />
                    {notification.time}
                  </span>
                </div>
                <div className="flex gap-2">
                  {!notification.read && (
                    <button className="p-2 text-blue-400 hover:text-blue-300 rounded-lg 
                                     hover:bg-slate-600 transition-colors">
                      <Check className="w-4 h-4" />
                    </button>
                  )}
                  <button className="p-2 text-slate-400 hover:text-slate-300 rounded-lg 
                                   hover:bg-slate-600 transition-colors">
                    <Ban className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Load More */}
        <div className="mt-6 text-center">
          <button className="px-4 py-2 bg-slate-700 text-slate-300 rounded-lg 
                           hover:bg-slate-600 transition-colors">
            Load More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileNotifications;