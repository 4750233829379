import React, { useState, useEffect } from 'react';
import { User, Mail, Phone, MapPin, Building2, Shield, Edit2, Camera } from 'lucide-react';
import { getUser } from '../../services/cognitoService';
import { getCurrentUser } from 'aws-amplify/auth';

const MyProfile = () => {
  const [userProfile, setUserProfile] = useState({
    name: '',
    email: '',
    phone: '',
    location: '', 
    department: '', 
    role: '', 
    joinDate: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Get the current authenticated user
        const currentUser = await getCurrentUser();
        
        // Get detailed user information from Cognito
        const userDetails = await getUser(currentUser.username);
        
        // Transform Cognito attributes into a more usable format
        const attributes = {};
        userDetails.UserAttributes.forEach(attr => {
          attributes[attr.Name] = attr.Value;
        });

        // Update the profile state with Cognito data
        setUserProfile(prev => ({
          ...prev,
          name: attributes.name || `${attributes.given_name || ''} ${attributes.family_name || ''}`.trim(),
          email: attributes.email || '',
          phone: attributes.phone_number || '',
          joinDate: new Date(userDetails.UserCreateDate).toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric'
          })
        }));

        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load profile data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm p-6">
        <p className="text-slate-400">Loading profile...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm p-6">
        <p className="text-red-400">{error}</p>
      </div>
    );
  }

  // Rest of your component remains the same
  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">My Profile</h2>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors gap-2">
            <Edit2 className="w-4 h-4" />
            Edit Profile
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Profile Overview */}
          <div className="lg:col-span-1">
            <div className="bg-slate-700 rounded-lg p-6">
              <div className="flex flex-col items-center">
                <div className="relative">
                  <div className="w-24 h-24 rounded-full bg-slate-600 flex items-center justify-center">
                    <User className="w-12 h-12 text-slate-400" />
                  </div>
                  <button className="absolute bottom-0 right-0 p-2 bg-blue-600 rounded-full hover:bg-blue-700 transition-colors">
                    <Camera className="w-4 h-4 text-white" />
                  </button>
                </div>
                <h3 className="mt-4 text-lg font-medium text-slate-200">{userProfile.name}</h3>
                <p className="text-slate-400">{userProfile.role}</p>
                <div className="mt-4 w-full">
                  <div className="flex items-center justify-center space-x-4">
                    <button className="p-2 text-slate-400 hover:text-slate-300">
                      <Mail className="w-5 h-5" />
                    </button>
                    <button className="p-2 text-slate-400 hover:text-slate-300">
                      <Phone className="w-5 h-5" />
                    </button>
                    <button className="p-2 text-slate-400 hover:text-slate-300">
                      <MapPin className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Profile Details */}
          <div className="lg:col-span-2">
            <div className="bg-slate-700 rounded-lg p-6">
              <h3 className="text-lg font-medium text-slate-200 mb-4">Profile Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="text-sm text-slate-400">Email</label>
                  <div className="flex items-center mt-1">
                    <Mail className="w-4 h-4 text-slate-400 mr-2" />
                    <span className="text-slate-200">{userProfile.email}</span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-slate-400">Phone</label>
                  <div className="flex items-center mt-1">
                    <Phone className="w-4 h-4 text-slate-400 mr-2" />
                    <span className="text-slate-200">{userProfile.phone}</span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-slate-400">Location</label>
                  <div className="flex items-center mt-1">
                    <MapPin className="w-4 h-4 text-slate-400 mr-2" />
                    <span className="text-slate-200">{userProfile.location}</span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-slate-400">Department</label>
                  <div className="flex items-center mt-1">
                    <Building2 className="w-4 h-4 text-slate-400 mr-2" />
                    <span className="text-slate-200">{userProfile.department}</span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-slate-400">Role</label>
                  <div className="flex items-center mt-1">
                    <Shield className="w-4 h-4 text-slate-400 mr-2" />
                    <span className="text-slate-200">{userProfile.role}</span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-slate-400">Join Date</label>
                  <div className="flex items-center mt-1">
                    <User className="w-4 h-4 text-slate-400 mr-2" />
                    <span className="text-slate-200">{userProfile.joinDate}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;