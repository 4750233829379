import React, { useState } from 'react';
import { Shield, Search, Edit2, Trash2, Plus, Users, Settings } from 'lucide-react';

const RoleManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showActionMenu, setShowActionMenu] = useState(null);

  // Sample roles data
  const roles = [
    {
      id: 1,
      name: 'Administrator',
      description: 'Full system access and control',
      userCount: 5,
      permissions: ['User Management', 'System Settings', 'Content Management', 'Billing Access'],
      createdAt: '2024-01-15'
    },
    {
      id: 2,
      name: 'HR Manager',
      description: 'Human resources management and employee data access',
      userCount: 8,
      permissions: ['User Management', 'Report Access'],
      createdAt: '2024-01-20'
    },
    {
      id: 3,
      name: 'Content Editor',
      description: 'Content creation and management access',
      userCount: 12,
      permissions: ['Content Management'],
      createdAt: '2024-02-01'
    },
    {
      id: 4,
      name: 'Finance Manager',
      description: 'Financial operations and billing access',
      userCount: 3,
      permissions: ['Billing Access', 'Report Access'],
      createdAt: '2024-02-10'
    }
  ];

  const filteredRoles = roles.filter(role =>
    role.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    role.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      {/* Header */}
      <div className="p-6 border-b border-slate-700">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex items-center">
            <Shield className="w-6 h-6 text-blue-500 mr-3" />
            <h1 className="text-2xl font-semibold text-slate-200">Role Management</h1>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-4 h-4" />
              <input
                type="text"
                placeholder="Search roles..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 bg-slate-700 border border-slate-600 rounded-lg
                         text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2
                         focus:ring-blue-500 focus:border-transparent w-full md:w-64"
              />
            </div>
            <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg
                           hover:bg-blue-700 transition-colors">
              <Plus className="w-4 h-4 mr-2" />
              Create Role
            </button>
          </div>
        </div>
      </div>

      {/* Roles Grid */}
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredRoles.map(role => (
            <div
              key={role.id}
              className="bg-slate-700/50 border border-slate-600 rounded-lg overflow-hidden"
            >
              <div className="p-4">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="text-lg font-medium text-slate-200">{role.name}</h3>
                    <p className="text-sm text-slate-400 mt-1">{role.description}</p>
                  </div>
                  
                  <div className="relative">
                    <button
                      onClick={() => setShowActionMenu(showActionMenu === role.id ? null : role.id)}
                      className="p-1 text-slate-400 hover:text-slate-200 transition-colors"
                    >
                      <Settings className="w-5 h-5" />
                    </button>
                    
                    {showActionMenu === role.id && (
                      <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1
                                    bg-slate-800 ring-1 ring-black ring-opacity-5 z-10">
                        <button className="flex items-center w-full px-4 py-2 text-sm
                                         text-slate-200 hover:bg-slate-700">
                          <Edit2 className="w-4 h-4 mr-3" />
                          Edit Role
                        </button>
                        <button className="flex items-center w-full px-4 py-2 text-sm
                                         text-slate-200 hover:bg-slate-700">
                          <Users className="w-4 h-4 mr-3" />
                          Manage Users
                        </button>
                        <button className="flex items-center w-full px-4 py-2 text-sm
                                         text-red-400 hover:bg-slate-700">
                          <Trash2 className="w-4 h-4 mr-3" />
                          Delete Role
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-4 flex items-center text-sm text-slate-400">
                  <Users className="w-4 h-4 mr-2" />
                  {role.userCount} users
                </div>

                <div className="mt-3 flex flex-wrap gap-2">
                  {role.permissions.map(permission => (
                    <span
                      key={permission}
                      className="px-2 py-1 text-xs font-semibold rounded-full
                               bg-blue-900/50 text-blue-400"
                    >
                      {permission}
                    </span>
                  ))}
                </div>
              </div>

              <div className="px-4 py-3 bg-slate-800 border-t border-slate-600">
                <span className="text-sm text-slate-400">
                  Created on {new Date(role.createdAt).toLocaleDateString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;