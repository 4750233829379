import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { UserCircle, Settings, LogOut, Sun, Moon, Users, Box, Truck } from 'lucide-react';

// Components
import SideNav from './components/SideNav';
import LoginPage from './components/LoginPage';
import LoadingSpinner from './components/LoadingSpinner';

// Context
import { AuthProvider, useAuth } from './contexts/AuthContext';

// Home Pages
import RecentActivity from './pages/home/RecentActivity';
import Notifications from './pages/home/Notifications';

// Company Pages
import CompanyProfile from './pages/company/CompanyProfile';
import Locations from './pages/company/Locations';
import Departments from './pages/company/Departments';
import Billing from './pages/company/Billing';
import Integrations from './pages/company/Integrations';

// Profile Pages
import MyProfile from './pages/profile/MyProfile';
import Preferences from './pages/profile/Preferences';
import Security from './pages/profile/Security';
import ProfileNotifications from './pages/profile/ProfileNotifications';

// Users Pages
import AllUsers from './pages/users/AllUsers';
//import AddUser from './pages/users/AddUser';
import UserGroups from './pages/users/UserGroups';
import AccessLogs from './pages/users/AccessLogs';

// Roles Pages
import RoleManagement from './pages/roles/RoleManagement';
import CreateRole from './pages/roles/CreateRole';
import Permissions from './pages/roles/Permissions';
import RoleAssignment from './pages/roles/RoleAssignment';

// Styles
import './App.css';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }
  
  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

// Main Layout Component
const MainLayout = ({ children }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved ? JSON.parse(saved) : false;
  });

  const { logout } = useAuth();

  const toggleDarkMode = () => {
    setIsDarkMode(prev => {
      const newValue = !prev;
      localStorage.setItem('darkMode', JSON.stringify(newValue));
      return newValue;
    });
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className={`flex flex-col lg:flex-row min-h-screen ${isDarkMode ? 'bg-slate-900' : 'bg-slate-50'}`}>
      <SideNav />
      <div className="flex-1 w-full lg:w-auto">
        <header className={`${isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-slate-200'} 
          border-b sticky top-0 z-30`}>
          <div className="px-6 py-4 flex justify-between items-center">
            <h1 className={`text-xl font-semibold ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}
              ml-12 lg:ml-0`}>
              KSP | Headquarters
            </h1>
            
            <div className="relative">
              <button 
                onClick={() => setUserMenuOpen(!userMenuOpen)}
                className={`flex items-center space-x-2 ${
                  isDarkMode ? 'text-slate-300 hover:text-slate-100' : 'text-slate-600 hover:text-slate-800'
                }`}
              >
                <UserCircle className="w-6 h-6" />
              </button>

              {userMenuOpen && (
                <div className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 border
                  ${isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-slate-200'}`}>
                  <button 
                    className={`flex items-center w-full px-4 py-2 text-sm
                      ${isDarkMode ? 'text-slate-300 hover:bg-slate-700' : 'text-slate-700 hover:bg-slate-50'}`}
                  >
                    <UserCircle className="w-4 h-4 mr-3" />
                    Profile
                  </button>
                  
                  <button 
                    className={`flex items-center w-full px-4 py-2 text-sm
                      ${isDarkMode ? 'text-slate-300 hover:bg-slate-700' : 'text-slate-700 hover:bg-slate-50'}`}
                  >
                    <Settings className="w-4 h-4 mr-3" />
                    Preferences
                  </button>

                  <button 
                    onClick={toggleDarkMode}
                    className={`flex items-center w-full px-4 py-2 text-sm
                      ${isDarkMode ? 'text-slate-300 hover:bg-slate-700' : 'text-slate-700 hover:bg-slate-50'}`}
                  >
                    {isDarkMode ? (
                      <Sun className="w-4 h-4 mr-3" />
                    ) : (
                      <Moon className="w-4 h-4 mr-3" />
                    )}
                    {isDarkMode ? 'Light Mode' : 'Dark Mode'}
                  </button>
                  
                  <hr className={`my-1 ${isDarkMode ? 'border-slate-700' : 'border-slate-200'}`} />
                  
                  <button 
                    onClick={handleLogout}
                    className={`flex items-center w-full px-4 py-2 text-sm text-red-500 
                      ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-slate-50'}`}
                  >
                    <LogOut className="w-4 h-4 mr-3" />
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>

        <main className="p-4 lg:p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

// Dashboard Content Component
const DashboardContent = () => {
  const systems = [
    {
      title: 'Labor Management System',
      icon: <Users className="w-12 h-12 mb-4" />,
      description: 'Manage workforce scheduling, time tracking, and performance metrics.',
      url: '/lms', // Updated to CloudFront URL
      color: 'bg-blue-600 hover:bg-blue-700',
      external: true
    },
    {
      title: 'Warehouse Management System',
      icon: <Box className="w-12 h-12 mb-4" />,
      description: 'COMING SOON! Control inventory, orders, and warehouse operations.',
      path: '/wms',
      color: 'bg-green-600 hover:bg-green-700'
    },
    {
      title: 'Transportation Management System',
      icon: <Truck className="w-12 h-12 mb-4" />,
      description: 'COMING SOON! Track fleet operations, routes, and deliveries.',
      path: '/tms',
      color: 'bg-purple-600 hover:bg-purple-700'
    }
  ];

  return (
    <div className={`rounded-lg shadow-sm border p-6 bg-slate-800 border-slate-700`}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {systems.map((system) => (
          system.external ? (
            <a 
              key={system.title}
              href={system.url} // Use the external URL for LMS
              target="_blank" // Opens in a new tab
              rel="noopener noreferrer"
              className={`${system.color} p-6 rounded-lg shadow-lg transform transition-all duration-200 
                          hover:scale-105 text-white text-center`}
            >
              <div className="flex flex-col items-center">
                {system.icon}
                <h2 className="text-xl font-bold mb-2">{system.title}</h2>
                <p className="text-sm opacity-90">{system.description}</p>
              </div>
            </a>
          ) : (
            <Link 
              key={system.title}
              to={system.path}
              className={`${system.color} p-6 rounded-lg shadow-lg transform transition-all duration-200 
                          hover:scale-105 text-white text-center`}
            >
              <div className="flex flex-col items-center">
                {system.icon}
                <h2 className="text-xl font-bold mb-2">{system.title}</h2>
                <p className="text-sm opacity-90">{system.description}</p>
              </div>
            </Link>
          )
        ))}
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          
          {/* Home Routes */}
          <Route path="/" element={
            <ProtectedRoute>
              <MainLayout>
                <DashboardContent />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/home/activity" element={
            <ProtectedRoute>
              <MainLayout>
                <RecentActivity />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/home/notifications" element={
            <ProtectedRoute>
              <MainLayout>
                <Notifications />
              </MainLayout>
            </ProtectedRoute>
          } />

          {/* Company Routes */}
          <Route path="/company/profile" element={
            <ProtectedRoute>
              <MainLayout>
                <CompanyProfile />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/company/locations" element={
            <ProtectedRoute>
              <MainLayout>
                <Locations />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/company/departments" element={
            <ProtectedRoute>
              <MainLayout>
                <Departments />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/company/billing" element={
            <ProtectedRoute>
              <MainLayout>
                <Billing />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/company/integrations" element={
            <ProtectedRoute>
              <MainLayout>
                <Integrations />
              </MainLayout>
            </ProtectedRoute>
          } />

          {/* Profile Routes */}
          <Route path="/profile" element={
            <ProtectedRoute>
              <MainLayout>
                <MyProfile />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/profile/preferences" element={
            <ProtectedRoute>
              <MainLayout>
                <Preferences />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/profile/security" element={
            <ProtectedRoute>
              <MainLayout>
                <Security />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/profile/notifications" element={
            <ProtectedRoute>
              <MainLayout>
                <ProfileNotifications />
              </MainLayout>
            </ProtectedRoute>
          } />

          {/* Users Routes */}
          <Route path="/users/all" element={
            <ProtectedRoute>
              <MainLayout>
                <AllUsers />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/users/groups" element={
            <ProtectedRoute>
              <MainLayout>
                <UserGroups />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/users/logs" element={
            <ProtectedRoute>
              <MainLayout>
                <AccessLogs />
              </MainLayout>
            </ProtectedRoute>
          } />

          {/* Roles Routes */}
          <Route path="/roles/manage" element={
            <ProtectedRoute>
              <MainLayout>
                <RoleManagement />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/roles/create" element={
            <ProtectedRoute>
              <MainLayout>
                <CreateRole />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/roles/permissions" element={
            <ProtectedRoute>
              <MainLayout>
                <Permissions />
              </MainLayout>
            </ProtectedRoute>
          } />
          <Route path="/roles/assign" element={
            <ProtectedRoute>
              <MainLayout>
                <RoleAssignment />
              </MainLayout>
            </ProtectedRoute>
          } />

          {/* Catch-all route - redirect to dashboard */}
          <Route path="*" element={
            <ProtectedRoute>
              <MainLayout>
                <DashboardContent />
              </MainLayout>
            </ProtectedRoute>
          } />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;