import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Menu, X, Home, Building2, UserCircle, Users, Shield,
  Warehouse, Truck, Clock, Search, ChevronDown, ChevronRight
} from 'lucide-react';

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const navigationItems = [
    {
      category: 'Home',
      icon: <Home className="w-5 h-5" />,
      items: [
        { name: 'Dashboard', path: '/' },
        { name: 'Recent Activity', path: '/home/activity' },
        { name: 'Notifications', path: '/home/notifications' }
      ]
    },
    {
      category: 'Company',
      icon: <Building2 className="w-5 h-5" />,
      items: [
        { name: 'Company Profile', path: '/company/profile' },
        { name: 'Locations', path: '/company/locations' },
        { name: 'Departments', path: '/company/departments' },
        { name: 'Billing', path: '/company/billing' },
        { name: 'Integration Settings', path: '/company/integrations' }
      ]
    },
    {
      category: 'Profile',
      icon: <UserCircle className="w-5 h-5" />,
      items: [
        { name: 'My Profile', path: '/profile' },
        { name: 'Preferences', path: '/profile/preferences' },
        { name: 'Security', path: '/profile/security' },
        { name: 'Notifications', path: '/profile/notifications' }
      ]
    },
    {
      category: 'Users',
      icon: <Users className="w-5 h-5" />,
      items: [
        { name: 'All Users', path: '/users/all' },
        //{ name: 'Add User', path: '/users/add' },
        { name: 'User Groups', path: '/users/groups' },
        { name: 'Access Logs', path: '/users/logs' }
      ]
    },
    {
      category: 'Roles',
      icon: <Shield className="w-5 h-5" />,
      items: [
        { name: 'Role Management', path: '/roles/manage' },
        { name: 'Create Role', path: '/roles/create' },
        { name: 'Permissions', path: '/roles/permissions' },
        { name: 'Role Assignment', path: '/roles/assign' }
      ]
    }
  ];

  // Filter navigation items based on search
  const filteredNavigation = useMemo(() => {
    if (!searchTerm.trim()) return navigationItems;

    return navigationItems.map(section => ({
      ...section,
      items: section.items.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        section.category.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })).filter(section => section.items.length > 0);
  }, [navigationItems, searchTerm]);

  // Auto-expand sections with matching items
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    if (!term.trim()) {
      setExpandedCategories({});
      return;
    }

    const newExpanded = {};
    filteredNavigation.forEach(section => {
      if (section.items.length > 0) {
        newExpanded[section.category] = true;
      }
    });
    setExpandedCategories(newExpanded);
  };

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  return (
    <>
      {/* Mobile Menu Button */}
      <button
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-md bg-slate-800 text-slate-200"
        onClick={toggleMenu}
      >
        {isMobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      {/* Overlay for mobile */}
      {isMobileMenuOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed lg:static inset-y-0 left-0 z-40 transition-transform duration-300 transform 
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
          h-screen bg-slate-800 bg-opacity-95 border-r border-slate-700 w-64 overflow-y-auto`}
      >
        {/* Search Bar */}
        <div className="p-4 border-b border-slate-700/50">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-4 h-4" />
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search..."
              className="w-full pl-10 pr-4 py-2 text-sm bg-slate-700/30 border border-slate-600/50 rounded-md 
                     focus:outline-none focus:ring-2 focus:ring-blue-400/50 focus:border-blue-400/50 
                     text-slate-200 placeholder-slate-400"
            />
          </div>
        </div>

        {/* Navigation Items */}
        <nav className="mt-4">
          {filteredNavigation.map((section) => (
            <div key={section.category} className="mb-2">
              <button
                onClick={() => toggleCategory(section.category)}
                className="w-full flex items-center px-4 py-2 text-slate-200 hover:bg-slate-700/50 transition-colors"
              >
                <span className="mr-3 text-slate-400">{section.icon}</span>
                <span className="flex-1 text-left">{section.category}</span>
                {expandedCategories[section.category] ? 
                  <ChevronDown className="w-4 h-4 text-slate-400" /> : 
                  <ChevronRight className="w-4 h-4 text-slate-400" />
                }
              </button>
              
              {expandedCategories[section.category] && (
                <div className="ml-4 pl-4 border-l border-slate-700/50">
                  {section.items.map(item => (
                    <Link 
                      key={item.path} 
                      to={item.path}
                      className="block py-2 px-4 text-sm text-slate-300 hover:bg-slate-700/30 
                               hover:text-slate-100 rounded-md transition-colors"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

export default SideNav;