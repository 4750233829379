// src/pages/company/Locations.js
import React from 'react';
import { MapPin, Users, Package, Phone, Plus, BarChart3 } from 'lucide-react';

const Locations = () => {
  const locations = [
    {
      id: 1,
      name: 'Main Warehouse',
      address: '123 Logistics Ave, Portland, OR',
      employees: 150,
      capacity: '50,000 sq ft',
      utilization: 78,
      phone: '+1 (555) 123-4567',
      status: 'active'
    },
    {
      id: 2,
      name: 'East Coast Hub',
      address: '456 Storage Blvd, Boston, MA',
      employees: 85,
      capacity: '30,000 sq ft',
      utilization: 92,
      phone: '+1 (555) 234-5678',
      status: 'active'
    },
    {
      id: 3,
      name: 'West Distribution',
      address: '789 Shipping Ln, Los Angeles, CA',
      employees: 120,
      capacity: '45,000 sq ft',
      utilization: 65,
      phone: '+1 (555) 345-6789',
      status: 'maintenance'
    }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Locations</h2>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors gap-2">
            <Plus className="w-4 h-4" />
            Add Location
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {locations.map((location) => (
            <div key={location.id} className="bg-slate-700 rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <MapPin className="w-6 h-6 text-blue-400" />
                    <h3 className="text-lg font-medium text-slate-200 ml-2">{location.name}</h3>
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm font-medium
                    ${location.status === 'active' ? 'bg-green-900/50 text-green-400' : 'bg-yellow-900/50 text-yellow-400'}`}>
                    {location.status === 'active' ? 'Active' : 'Maintenance'}
                  </span>
                </div>
                
                <div className="space-y-3">
                  <p className="text-slate-400">{location.address}</p>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center text-slate-300">
                      <Users className="w-4 h-4 mr-2" />
                      <span>{location.employees} employees</span>
                    </div>
                    <div className="flex items-center text-slate-300">
                      <Package className="w-4 h-4 mr-2" />
                      <span>{location.capacity}</span>
                    </div>
                  </div>
                  
                  <div className="mt-4">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-slate-400">Storage Utilization</span>
                      <span className="text-sm text-slate-400">{location.utilization}%</span>
                    </div>
                    <div className="w-full bg-slate-600 rounded-full h-2">
                      <div
                        className={`h-2 rounded-full ${
                          location.utilization > 90 ? 'bg-red-400' : 
                          location.utilization > 75 ? 'bg-yellow-400' : 'bg-green-400'
                        }`}
                        style={{ width: `${location.utilization}%` }}
                      ></div>
                    </div>
                  </div>

                  <div className="flex items-center text-slate-300">
                    <Phone className="w-4 h-4 mr-2" />
                    <span>{location.phone}</span>
                  </div>
                </div>
              </div>
              
              <div className="bg-slate-800 border-t border-slate-600 p-4">
                <div className="flex justify-between">
                  <button className="text-blue-400 hover:text-blue-300 text-sm">View Details</button>
                  <div className="flex gap-3">
                    <button className="text-slate-400 hover:text-slate-300 text-sm">Edit</button>
                    <button className="text-red-400 hover:text-red-300 text-sm">Remove</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Locations;