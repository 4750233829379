// src/pages/home/Notifications.js
import React from 'react';
import { Bell, Info, AlertTriangle, CheckCircle, Settings, Trash2 } from 'lucide-react';

const Notifications = () => {
  const notifications = [
    {
      id: 1,
      type: 'alert',
      title: 'System Update Required',
      message: 'A new security update is available for your system.',
      time: '10 minutes ago',
      read: false
    },
    {
      id: 2,
      type: 'warning',
      title: 'Storage Warning',
      message: 'East Coast Hub storage capacity is reaching 90%.',
      time: '1 hour ago',
      read: false
    },
    {
      id: 3,
      type: 'success',
      title: 'Backup Completed',
      message: 'Weekly system backup completed successfully.',
      time: '2 hours ago',
      read: true
    },
    {
      id: 4,
      type: 'info',
      title: 'New Feature Available',
      message: 'Check out our new reporting dashboard.',
      time: '1 day ago',
      read: true
    }
  ];

  const getIcon = (type) => {
    switch (type) {
      case 'alert':
        return <AlertTriangle className="w-5 h-5 text-red-400" />;
      case 'warning':
        return <AlertTriangle className="w-5 h-5 text-yellow-400" />;
      case 'success':
        return <CheckCircle className="w-5 h-5 text-green-400" />;
      case 'info':
        return <Info className="w-5 h-5 text-blue-400" />;
      default:
        return <Bell className="w-5 h-5 text-slate-400" />;
    }
  };

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Notifications</h2>
          <div className="flex gap-3">
            <button className="flex items-center px-4 py-2 text-slate-400 hover:text-slate-300 gap-2">
              <Settings className="w-4 h-4" />
              Settings
            </button>
            <button className="flex items-center px-4 py-2 text-slate-400 hover:text-slate-300 gap-2">
              <Trash2 className="w-4 h-4" />
              Clear All
            </button>
          </div>
        </div>

        <div className="space-y-4">
          {notifications.map((notification) => (
            <div 
              key={notification.id} 
              className={`bg-slate-700 rounded-lg p-4 ${
                !notification.read ? 'border-l-4 border-blue-400' : ''
              }`}
            >
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {getIcon(notification.type)}
                </div>
                <div className="ml-4 flex-1">
                  <div className="flex items-center justify-between">
                    <p className="text-slate-200 font-medium">{notification.title}</p>
                    <span className="text-sm text-slate-500">{notification.time}</span>
                  </div>
                  <p className="text-slate-400 mt-1">{notification.message}</p>
                </div>
                <button className="ml-4 text-slate-400 hover:text-slate-300">
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
              {!notification.read && (
                <div className="mt-3 flex justify-end">
                  <button className="text-sm text-blue-400 hover:text-blue-300">
                    Mark as read
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-6 text-center">
          <button className="text-blue-400 hover:text-blue-300">
            View All Notifications
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;