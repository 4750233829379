import React, { useState } from 'react';
import { Shield, Search, Edit2, Trash2, Plus } from 'lucide-react';

const Permissions = () => {
  const [searchTerm, setSearchTerm] = useState('');

  // Sample permissions data
  const permissions = [
    {
      id: 1,
      name: 'User Management',
      description: 'Create, edit, and delete users',
      roles: ['Admin', 'HR Manager'],
      type: 'Core'
    },
    {
      id: 2,
      name: 'Content Management',
      description: 'Manage content and assets',
      roles: ['Admin', 'Content Editor'],
      type: 'Content'
    },
    {
      id: 3,
      name: 'Report Access',
      description: 'View and download reports',
      roles: ['Admin', 'Analyst'],
      type: 'Reports'
    },
    {
      id: 4,
      name: 'System Settings',
      description: 'Configure system preferences',
      roles: ['Admin'],
      type: 'System'
    },
    {
      id: 5,
      name: 'Billing Access',
      description: 'Manage billing and subscriptions',
      roles: ['Admin', 'Finance Manager'],
      type: 'Billing'
    }
  ];

  const filteredPermissions = permissions.filter(permission =>
    permission.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    permission.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      {/* Header */}
      <div className="p-6 border-b border-slate-700">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex items-center">
            <Shield className="w-6 h-6 text-blue-500 mr-3" />
            <h1 className="text-2xl font-semibold text-slate-200">Permissions</h1>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-4 h-4" />
              <input
                type="text"
                placeholder="Search permissions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 bg-slate-700 border border-slate-600 rounded-lg
                         text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2
                         focus:ring-blue-500 focus:border-transparent w-full md:w-64"
              />
            </div>
            <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg
                           hover:bg-blue-700 transition-colors">
              <Plus className="w-4 h-4 mr-2" />
              Add Permission
            </button>
          </div>
        </div>
      </div>

      {/* Permissions List */}
      <div className="p-6">
        <div className="space-y-4">
          {filteredPermissions.map(permission => (
            <div
              key={permission.id}
              className="bg-slate-700/50 border border-slate-600 rounded-lg p-4"
            >
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-lg font-medium text-slate-200">{permission.name}</h3>
                  <p className="text-sm text-slate-400 mt-1">{permission.description}</p>
                  
                  <div className="mt-3 flex flex-wrap gap-2">
                    <span className="px-2 py-1 text-xs font-semibold rounded-full
                                 bg-blue-900/50 text-blue-400">
                      {permission.type}
                    </span>
                    {permission.roles.map(role => (
                      <span
                        key={role}
                        className="px-2 py-1 text-xs font-semibold rounded-full
                                 bg-slate-600/50 text-slate-300"
                      >
                        {role}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="flex space-x-2">
                  <button className="p-2 text-slate-400 hover:text-slate-200 transition-colors">
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button className="p-2 text-slate-400 hover:text-red-400 transition-colors">
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Permissions;