import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';

const amplifyConfig = {
  Auth: {
    Cognito: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_JzOHkpJjj',
      userPoolClientId: '19drj87o1kt5jsobmbbhv27vcf',
      identityPoolId: 'us-east-1:2feb6a03-9354-4c16-bb9a-4ce542d5c818',
      signUpVerificationMethod: 'code',
    }
  }
};

Amplify.configure(amplifyConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();