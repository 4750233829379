import React, { useState } from 'react';
import { Shield, Search, Users, AlertCircle } from 'lucide-react';

const RoleAssignment = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Sample users data
  const users = [
    {
      id: 1,
      name: 'John Smith',
      email: 'john.smith@example.com',
      currentRole: 'Content Editor',
      department: 'Marketing'
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      email: 'sarah.j@example.com',
      currentRole: 'HR Manager',
      department: 'Human Resources'
    },
    {
      id: 3,
      name: 'Michael Chen',
      email: 'm.chen@example.com',
      currentRole: 'Administrator',
      department: 'IT'
    },
    {
      id: 4,
      name: 'Emma Wilson',
      email: 'e.wilson@example.com',
      currentRole: 'Finance Manager',
      department: 'Finance'
    }
  ];

  // Sample roles
  const roles = [
    'Administrator',
    'HR Manager',
    'Content Editor',
    'Finance Manager',
    'Analyst',
    'Support Agent'
  ];

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUserSelection = (userId) => {
    setSelectedUsers(prev =>
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleRoleAssignment = () => {
    if (!selectedRole) {
      alert('Please select a role to assign');
      return;
    }
    console.log('Assigning role:', selectedRole, 'to users:', selectedUsers);
  };

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      {/* Header */}
      <div className="p-6 border-b border-slate-700">
        <div className="flex items-center mb-6">
          <Shield className="w-6 h-6 text-blue-500 mr-3" />
          <h1 className="text-2xl font-semibold text-slate-200">Role Assignment</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-slate-300 mb-2">
              Select Role to Assign
            </label>
            <select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className="w-full px-3 py-2 bg-slate-700 border border-slate-600 rounded-lg
                       text-slate-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a role...</option>
              {roles.map(role => (
                <option key={role} value={role}>{role}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-slate-300 mb-2">
              Search Users
            </label>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-4 h-4" />
              <input
                type="text"
                placeholder="Search by name or email..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-slate-700 border border-slate-600 rounded-lg
                         text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2
                         focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
      </div>

      {/* User List */}
      <div className="p-6">
        {selectedUsers.length > 0 && (
          <div className="mb-4 p-3 bg-blue-900/30 border border-blue-800/50 rounded-lg flex items-center">
            <Users className="w-5 h-5 text-blue-400 mr-2" />
            <span className="text-blue-200">
              {selectedUsers.length} users selected
            </span>
            <button
              onClick={handleRoleAssignment}
              className="ml-auto px-4 py-1 bg-blue-600 text-white rounded-md
                       hover:bg-blue-700 transition-colors"
            >
              Assign Role
            </button>
          </div>
        )}

        <div className="space-y-4">
          {filteredUsers.length === 0 ? (
            <div className="text-center py-8 text-slate-400">
              <AlertCircle className="w-6 h-6 mx-auto mb-2" />
              No users found matching your search
            </div>
          ) : (
            filteredUsers.map(user => (
              <div
                key={user.id}
                className="flex items-center p-4 bg-slate-700/50 border border-slate-600 rounded-lg
                         hover:bg-slate-700 transition-colors cursor-pointer"
                onClick={() => handleUserSelection(user.id)}
              >
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => {}}
                  className="h-4 w-4 rounded border-slate-300 text-blue-600
                           focus:ring-blue-500 bg-slate-600"
                />
                
                <div className="ml-4 flex-1">
                  <h3 className="text-slate-200 font-medium">{user.name}</h3>
                  <p className="text-sm text-slate-400">{user.email}</p>
                </div>
                
                <div className="text-right">
                  <span className="px-2 py-1 text-xs font-semibold rounded-full
                               bg-slate-600/50 text-slate-300">
                    {user.currentRole}
                  </span>
                  <p className="text-sm text-slate-400 mt-1">{user.department}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default RoleAssignment;