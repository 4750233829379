// src/pages/company/BillingPage.js
import React from 'react';
import { CreditCard, DollarSign, FileText, AlertCircle } from 'lucide-react';

const BillingPage = () => {
  const billingInfo = {
    currentPlan: 'Enterprise',
    nextBilling: '2024-12-01',
    status: 'Active',
    amount: '$2,499.00',
    paymentMethod: 'VISA ending in 4242'
  };

  const recentInvoices = [
    { id: 1, date: '2024-11-01', amount: '$2,499.00', status: 'Paid' },
    { id: 2, date: '2024-10-01', amount: '$2,499.00', status: 'Paid' },
    { id: 3, date: '2024-09-01', amount: '$2,499.00', status: 'Paid' }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <h2 className="text-xl font-semibold text-slate-200 mb-6">Billing Information</h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Current Plan Card */}
          <div className="bg-slate-700 rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-slate-200">Current Plan</h3>
              <CreditCard className="w-5 h-5 text-blue-400" />
            </div>
            <div className="space-y-3">
              <div className="flex justify-between">
                <span className="text-slate-400">Plan Type</span>
                <span className="text-slate-200">{billingInfo.currentPlan}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-slate-400">Next Billing</span>
                <span className="text-slate-200">{billingInfo.nextBilling}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-slate-400">Amount</span>
                <span className="text-slate-200">{billingInfo.amount}</span>
              </div>
            </div>
          </div>

          {/* Payment Method Card */}
          <div className="bg-slate-700 rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-slate-200">Payment Method</h3>
              <DollarSign className="w-5 h-5 text-blue-400" />
            </div>
            <div className="flex items-center justify-between">
              <span className="text-slate-200">{billingInfo.paymentMethod}</span>
              <button className="text-blue-400 hover:text-blue-300 text-sm">Update</button>
            </div>
          </div>
        </div>

        {/* Recent Invoices */}
        <div className="mt-6">
          <h3 className="text-lg font-medium text-slate-200 mb-4">Recent Invoices</h3>
          <div className="bg-slate-700 rounded-lg overflow-hidden">
            <table className="w-full">
              <thead>
                <tr className="bg-slate-600">
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase">Amount</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase">Status</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-slate-300 uppercase">Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-600">
                {recentInvoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td className="px-6 py-4 text-sm text-slate-200">{invoice.date}</td>
                    <td className="px-6 py-4 text-sm text-slate-200">{invoice.amount}</td>
                    <td className="px-6 py-4">
                      <span className="px-2 py-1 text-xs font-semibold rounded-full bg-green-900/50 text-green-400">
                        {invoice.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right">
                      <button className="text-blue-400 hover:text-blue-300 text-sm">Download</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPage;