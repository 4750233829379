// src/pages/company/CompanyProfile.js
import React from 'react';
import { Building2, MapPin, Phone, Mail, Edit2 } from 'lucide-react';

const CompanyProfile = () => {
  const companyInfo = {
    name: 'KSP Logistics',
    logo: '/logo.png',
    description: 'Leading provider of third-party logistics and supply chain solutions.',
    address: '123 Logistics Way, Portland, OR 97201',
    phone: '+1 (555) 123-4567',
    email: 'contact@ksp3plhq.com',
    website: 'www.ksp3plhq.com',
    founded: '2010',
    employees: '500+'
  };

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Company Profile</h2>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors gap-2">
            <Edit2 className="w-4 h-4" />
            Edit Profile
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Company Overview */}
          <div className="bg-slate-700 rounded-lg p-6">
            <div className="flex items-center mb-4">
              <Building2 className="w-8 h-8 text-blue-400" />
              <h3 className="text-lg font-medium text-slate-200 ml-3">{companyInfo.name}</h3>
            </div>
            <p className="text-slate-400 mb-6">{companyInfo.description}</p>
            <div className="space-y-4">
              <div className="flex items-center text-slate-300">
                <MapPin className="w-5 h-5 mr-3" />
                <span>{companyInfo.address}</span>
              </div>
              <div className="flex items-center text-slate-300">
                <Phone className="w-5 h-5 mr-3" />
                <span>{companyInfo.phone}</span>
              </div>
              <div className="flex items-center text-slate-300">
                <Mail className="w-5 h-5 mr-3" />
                <span>{companyInfo.email}</span>
              </div>
            </div>
          </div>

          {/* Company Details */}
          <div className="bg-slate-700 rounded-lg p-6">
            <h3 className="text-lg font-medium text-slate-200 mb-4">Company Details</h3>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-slate-400">Founded</span>
                <span className="text-slate-200">{companyInfo.founded}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-slate-400">Employees</span>
                <span className="text-slate-200">{companyInfo.employees}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-slate-400">Website</span>
                <span className="text-slate-200">{companyInfo.website}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
