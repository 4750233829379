// src/pages/users/AccessLogs.js
import React from 'react';
import { Clock, User, Shield, AlertCircle, Filter, Download, RefreshCw } from 'lucide-react';

const AccessLogs = () => {
  const logs = [
    {
      id: 1,
      user: 'John Doe',
      action: 'Login',
      resource: 'Dashboard',
      status: 'success',
      ip: '192.168.1.1',
      location: 'Portland, OR',
      timestamp: '2024-03-15 14:30:45'
    },
    {
      id: 2,
      user: 'Sarah Wilson',
      action: 'Access Denied',
      resource: 'Admin Panel',
      status: 'failed',
      ip: '192.168.1.2',
      location: 'Seattle, WA',
      timestamp: '2024-03-15 14:28:30'
    },
    {
      id: 3,
      user: 'Mike Johnson',
      action: 'File Download',
      resource: 'Reports/Q4-2023.pdf',
      status: 'success',
      ip: '192.168.1.3',
      location: 'Boston, MA',
      timestamp: '2024-03-15 14:25:12'
    }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Access Logs</h2>
          <div className="flex gap-3">
            <button className="flex items-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg 
                             hover:bg-slate-600 transition-colors gap-2">
              <Filter className="w-4 h-4" />
              Filter
            </button>
            <button className="flex items-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg 
                             hover:bg-slate-600 transition-colors gap-2">
              <Download className="w-4 h-4" />
              Export
            </button>
            <button className="flex items-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg 
                             hover:bg-slate-600 transition-colors gap-2">
              <RefreshCw className="w-4 h-4" />
              Refresh
            </button>
          </div>
        </div>

        {/* Filters/Search Section */}
        <div className="bg-slate-700 rounded-lg p-4 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm text-slate-400 mb-1">User</label>
              <input 
                type="text" 
                className="w-full bg-slate-600 border border-slate-500 rounded-lg px-3 py-2 
                         text-slate-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search user..."
              />
            </div>
            <div>
              <label className="block text-sm text-slate-400 mb-1">Action</label>
              <select className="w-full bg-slate-600 border border-slate-500 rounded-lg px-3 py-2 
                              text-slate-200 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option>All Actions</option>
                <option>Login</option>
                <option>Logout</option>
                <option>Access Denied</option>
              </select>
            </div>
            <div>
              <label className="block text-sm text-slate-400 mb-1">Status</label>
              <select className="w-full bg-slate-600 border border-slate-500 rounded-lg px-3 py-2 
                              text-slate-200 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option>All Status</option>
                <option>Success</option>
                <option>Failed</option>
              </select>
            </div>
            <div>
              <label className="block text-sm text-slate-400 mb-1">Date Range</label>
              <input 
                type="date" 
                className="w-full bg-slate-600 border border-slate-500 rounded-lg px-3 py-2 
                         text-slate-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Logs Table */}
        <div className="bg-slate-700 rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-slate-600">
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase tracking-wider">
                    Timestamp
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase tracking-wider">
                    User
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase tracking-wider">
                    Action
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase tracking-wider">
                    Resource
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase tracking-wider">
                    IP Address
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-300 uppercase tracking-wider">
                    Location
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-600">
                {logs.map((log) => (
                  <tr key={log.id} className="hover:bg-slate-600/50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-300">
                      <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-2 text-slate-400" />
                        {log.timestamp}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-300">
                      <div className="flex items-center">
                        <User className="w-4 h-4 mr-2 text-slate-400" />
                        {log.user}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-300">
                      {log.action}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-300">
                      {log.resource}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs rounded-full ${
                        log.status === 'success' 
                          ? 'bg-green-900/50 text-green-400' 
                          : 'bg-red-900/50 text-red-400'
                      }`}>
                        {log.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-300">
                      {log.ip}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-300">
                      {log.location}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="mt-6 flex items-center justify-between">
          <div className="text-sm text-slate-400">
            Showing 1 to 3 of 100 entries
          </div>
          <div className="flex gap-2">
            <button className="px-4 py-2 bg-slate-700 text-slate-300 rounded-lg 
                             hover:bg-slate-600 transition-colors">
              Previous
            </button>
            <button className="px-4 py-2 bg-slate-700 text-slate-300 rounded-lg 
                             hover:bg-slate-600 transition-colors">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessLogs;