// src/pages/profile/Security.js
import React from 'react';
import { Key, Shield, Smartphone, History, AlertCircle } from 'lucide-react';

const Security = () => {
  const loginHistory = [
    {
      id: 1,
      device: 'Chrome on Windows',
      location: 'Portland, OR',
      ip: '192.168.1.1',
      time: '2 hours ago',
      status: 'success'
    },
    {
      id: 2,
      device: 'Mobile App on iPhone',
      location: 'Seattle, WA',
      ip: '192.168.1.2',
      time: '1 day ago',
      status: 'success'
    },
    {
      id: 3,
      device: 'Firefox on MacOS',
      location: 'Vancouver, BC',
      ip: '192.168.1.3',
      time: '3 days ago',
      status: 'failed'
    }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <h2 className="text-xl font-semibold text-slate-200 mb-6">Security Settings</h2>

        {/* Password Section */}
        <div className="bg-slate-700 rounded-lg p-6 mb-6">
          <div className="flex items-start justify-between">
            <div>
              <div className="flex items-center gap-2">
                <Key className="w-5 h-5 text-blue-400" />
                <h3 className="text-lg font-medium text-slate-200">Password</h3>
              </div>
              <p className="text-slate-400 mt-1">Last changed 30 days ago</p>
            </div>
            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors">
              Change Password
            </button>
          </div>
        </div>

        {/* Two-Factor Authentication */}
        <div className="bg-slate-700 rounded-lg p-6 mb-6">
          <div className="flex items-start justify-between">
            <div>
              <div className="flex items-center gap-2">
                <Shield className="w-5 h-5 text-blue-400" />
                <h3 className="text-lg font-medium text-slate-200">Two-Factor Authentication</h3>
              </div>
              <p className="text-slate-400 mt-1">Add an extra layer of security to your account</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-slate-600 rounded-full peer peer-checked:after:translate-x-full 
                          peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 
                          after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 
                          after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>

        {/* Connected Devices */}
        <div className="bg-slate-700 rounded-lg p-6 mb-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Smartphone className="w-5 h-5 text-blue-400" />
              <h3 className="text-lg font-medium text-slate-200">Connected Devices</h3>
            </div>
            <button className="text-blue-400 hover:text-blue-300">Manage Devices</button>
          </div>
          <div className="space-y-3">
            <div className="flex items-center justify-between p-3 bg-slate-600 rounded-lg">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-slate-500 rounded-full">
                  <History className="w-4 h-4 text-slate-300" />
                </div>
                <div>
                  <p className="text-slate-200">Current Device</p>
                  <p className="text-sm text-slate-400">Chrome on Windows</p>
                </div>
              </div>
              <button className="text-red-400 hover:text-red-300 text-sm">Revoke</button>
            </div>
          </div>
        </div>

        {/* Login History */}
        <div className="bg-slate-700 rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <History className="w-5 h-5 text-blue-400" />
              <h3 className="text-lg font-medium text-slate-200">Login History</h3>
            </div>
          </div>
          <div className="space-y-3">
            {loginHistory.map((login) => (
              <div key={login.id} className="flex items-center justify-between p-3 bg-slate-600 rounded-lg">
                <div>
                  <div className="flex items-center gap-2">
                    <span className="text-slate-200">{login.device}</span>
                    {login.status === 'failed' && (
                      <span className="flex items-center gap-1 text-xs text-red-400">
                        <AlertCircle className="w-3 h-3" />
                        Failed attempt
                      </span>
                    )}
                  </div>
                  <div className="text-sm text-slate-400 mt-1">
                    {login.location} • {login.ip}
                  </div>
                  <div className="text-xs text-slate-500 mt-1">{login.time}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;