// src/pages/company/Integrations.js
import React from 'react';
import { Link2, CheckCircle, XCircle, Settings, AlertCircle } from 'lucide-react';

const Integrations = () => {
  const integrations = [
    {
      id: 1,
      name: 'Warehouse Management System',
      status: 'connected',
      lastSync: '2 hours ago',
      description: 'Integration with main warehouse management system.',
      apiKey: '****-****-****-3F7A'
    },
    {
      id: 2,
      name: 'Fleet Management System',
      status: 'connected',
      lastSync: '1 hour ago',
      description: 'Real-time fleet tracking and management integration.',
      apiKey: '****-****-****-8B2C'
    },
    {
      id: 3,
      name: 'Customer CRM',
      status: 'error',
      lastSync: '1 day ago',
      description: 'Customer relationship management integration.',
      error: 'API key expired'
    },
    {
      id: 4,
      name: 'Accounting Software',
      status: 'disconnected',
      description: 'Financial and accounting system integration.',
    }
  ];

  const getStatusIcon = (status) => {
    switch (status) {
      case 'connected':
        return <CheckCircle className="w-5 h-5 text-green-400" />;
      case 'error':
        return <AlertCircle className="w-5 h-5 text-red-400" />;
      case 'disconnected':
        return <XCircle className="w-5 h-5 text-slate-400" />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Integration Settings</h2>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors gap-2">
            <Link2 className="w-4 h-4" />
            Add Integration
          </button>
        </div>

        <div className="space-y-4">
          {integrations.map((integration) => (
            <div key={integration.id} className="bg-slate-700 rounded-lg p-6">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center">
                    <h3 className="text-lg font-medium text-slate-200">{integration.name}</h3>
                    <div className="ml-3">
                      {getStatusIcon(integration.status)}
                    </div>
                  </div>
                  <p className="text-slate-400 mt-1">{integration.description}</p>
                  {integration.lastSync && (
                    <p className="text-sm text-slate-500 mt-2">Last synced: {integration.lastSync}</p>
                  )}
                  {integration.error && (
                    <p className="text-sm text-red-400 mt-2">Error: {integration.error}</p>
                  )}
                  {integration.apiKey && (
                    <p className="text-sm text-slate-500 mt-2">API Key: {integration.apiKey}</p>
                  )}
                </div>
                <button className="p-2 hover:bg-slate-600 rounded-lg transition-colors">
                  <Settings className="w-5 h-5 text-slate-400" />
                </button>
              </div>
              <div className="mt-4 flex gap-3">
                {integration.status === 'connected' && (
                  <>
                    <button className="text-sm text-blue-400 hover:text-blue-300">Refresh</button>
                    <button className="text-sm text-red-400 hover:text-red-300">Disconnect</button>
                  </>
                )}
                {integration.status === 'disconnected' && (
                  <button className="text-sm text-blue-400 hover:text-blue-300">Connect</button>
                )}
                {integration.status === 'error' && (
                  <button className="text-sm text-blue-400 hover:text-blue-300">Retry</button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Integrations;