import {
  CognitoIdentityProviderClient,
  AdminCreateUserCommand,
  ListUsersCommand,
  AdminGetUserCommand,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
  AdminResetUserPasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

const USER_POOL_ID = 'us-east-1_JzOHkpJjj';
const REGION = 'us-east-1';
const IDENTITY_POOL_ID = 'us-east-1:2feb6a03-9354-4c16-bb9a-4ce542d5c818';

const getCredentials = async () => {
  try {
    const session = await fetchAuthSession();
    console.log('Auth session:', session);
    if (!session?.credentials) {
      throw new Error('No credentials available');
    }

    // Construct credentials configuration
    const credentials = {
      accessKeyId: session.credentials.accessKeyId,
      secretAccessKey: session.credentials.secretAccessKey,
      sessionToken: session.credentials.sessionToken,
    };

    return {
      region: REGION,
      credentials: credentials
    };
  } catch (error) {
    console.error('Error getting credentials:', error);
    throw new Error('Failed to obtain credentials: ' + error.message);
  }
};

export const createUser = async (userData) => {
  try {
    console.log('Getting credentials for user creation...');
    const config = await getCredentials();
    
    console.log('Creating Cognito client...');
    const client = new CognitoIdentityProviderClient(config);

    const { email, phoneNumber, givenName, familyName } = userData;
    const name = `${givenName} ${familyName}`;
    
    console.log('Preparing user attributes...', {
      email,
      phoneNumber,
      name
    });

    const command = new AdminCreateUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: email, // Using email as username since we're using email sign-in
      UserAttributes: [
        { Name: 'email', Value: email },
        { Name: 'email_verified', Value: 'true' },
        ...(phoneNumber ? [{ Name: 'phone_number', Value: phoneNumber }] : []),
        { Name: 'given_name', Value: givenName },
        { Name: 'family_name', Value: familyName },
        { Name: 'name', Value: name }
      ],
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: true,
      MessageAction: 'SUPPRESS' // We'll handle the welcome email separately
    });

    console.log('Sending create user command...');
    const response = await client.send(command);
    console.log('User created successfully:', response);
    return response;
  } catch (error) {
    console.error('Error creating user:', {
      name: error.name,
      message: error.message,
      code: error.$metadata?.httpStatusCode,
      requestId: error.$metadata?.requestId
    });
    
    // Transform common errors into more user-friendly messages
    if (error.name === 'UsernameExistsException') {
      throw new Error('A user with this email already exists');
    } else if (error.name === 'InvalidParameterException') {
      throw new Error('Please check the provided user information');
    }
    
    throw error;
  }
};

export const listUsers = async () => {
  try {
    console.log('Getting credentials...');
    const config = await getCredentials();
    
    console.log('Creating Cognito client...');
    const client = new CognitoIdentityProviderClient(config);

    console.log('Preparing ListUsers command...');
    const command = new ListUsersCommand({
      UserPoolId: USER_POOL_ID,
      Limit: 60
    });

    console.log('Sending ListUsers command...');
    const response = await client.send(command);
    console.log('Raw response:', JSON.stringify(response, null, 2));
    
    if (!response.Users) {
      console.log('No users found in response');
      return [];
    }

    // Transform the response to include only necessary data
    const users = response.Users.map(user => ({
      Username: user.Username,
      Enabled: user.Enabled,
      UserStatus: user.UserStatus,
      UserCreateDate: user.UserCreateDate,
      Attributes: user.Attributes || []
    }));

    console.log(`Processed ${users.length} users:`, JSON.stringify(users, null, 2));
    return users;
    
  } catch (error) {
    console.error('Error in listUsers:', {
      name: error.name,
      message: error.message,
      code: error.$metadata?.httpStatusCode,
      requestId: error.$metadata?.requestId,
      stack: error.stack
    });
    
    if (error.name === 'NotAuthorizedException') {
      throw new Error('Your session has expired. Please log in again.');
    } else if (error.name === 'AccessDeniedException') {
      throw new Error('You do not have permission to list users.');
    }
    
    throw error;
  }
};

export const getUser = async (username) => {
  try {
    console.log(`Getting user details for: ${username}`);
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminGetUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    const response = await client.send(command);
    console.log('User details retrieved:', response);
    return response;
  } catch (error) {
    console.error('Error fetching user:', {
      name: error.name,
      message: error.message,
      code: error.$metadata?.httpStatusCode,
    });
    
    if (error.name === 'UserNotFoundException') {
      throw new Error('User not found');
    }
    
    throw error;
  }
};

export const disableUser = async (username) => {
  try {
    console.log(`Disabling user: ${username}`);
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminDisableUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    await client.send(command);
    console.log('User disabled successfully');
    return { success: true, message: 'User disabled successfully' };
  } catch (error) {
    console.error('Error disabling user:', {
      name: error.name,
      message: error.message,
      code: error.$metadata?.httpStatusCode,
    });
    
    if (error.name === 'UserNotFoundException') {
      throw new Error('User not found');
    }
    
    throw error;
  }
};

export const enableUser = async (username) => {
  try {
    console.log(`Enabling user: ${username}`);
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminEnableUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    await client.send(command);
    console.log('User enabled successfully');
    return { success: true, message: 'User enabled successfully' };
  } catch (error) {
    console.error('Error enabling user:', {
      name: error.name,
      message: error.message,
      code: error.$metadata?.httpStatusCode,
    });
    
    if (error.name === 'UserNotFoundException') {
      throw new Error('User not found');
    }
    
    throw error;
  }
};

export const resetUserPassword = async (username) => {
  try {
    console.log(`Initiating password reset for user: ${username}`);
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminResetUserPasswordCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    await client.send(command);
    console.log('Password reset initiated successfully');
    return { success: true, message: 'Password reset initiated successfully' };
  } catch (error) {
    console.error('Error resetting password:', {
      name: error.name,
      message: error.message,
      code: error.$metadata?.httpStatusCode,
    });
    
    if (error.name === 'UserNotFoundException') {
      throw new Error('User not found');
    }
    
    throw error;
  }
};