// src/pages/company/Departments.js
import React from 'react';
import { Users, Briefcase, LineChart, Plus } from 'lucide-react';

const Departments = () => {
  const departments = [
    {
      id: 1,
      name: 'Operations',
      head: 'Jane Smith',
      employees: 45,
      budget: '$1.2M',
      projects: 12
    },
    {
      id: 2,
      name: 'Logistics',
      head: 'Mike Johnson',
      employees: 32,
      budget: '$800K',
      projects: 8
    },
    {
      id: 3,
      name: 'Supply Chain',
      head: 'Sarah Wilson',
      employees: 28,
      budget: '$950K',
      projects: 10
    }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Departments</h2>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors gap-2">
            <Plus className="w-4 h-4" />
            Add Department
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {departments.map((dept) => (
            <div key={dept.id} className="bg-slate-700 rounded-lg p-6">
              <div className="flex items-center mb-4">
                <Briefcase className="w-6 h-6 text-blue-400" />
                <h3 className="text-lg font-medium text-slate-200 ml-2">{dept.name}</h3>
              </div>
              <div className="space-y-3">
                <p className="text-slate-400">Head: {dept.head}</p>
                <div className="flex items-center justify-between text-slate-300">
                  <div className="flex items-center">
                    <Users className="w-4 h-4 mr-2" />
                    <span>{dept.employees}</span>
                  </div>
                  <div className="flex items-center">
                    <LineChart className="w-4 h-4 mr-2" />
                    <span>{dept.projects} projects</span>
                  </div>
                </div>
                <p className="text-slate-300">Budget: {dept.budget}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Departments;