// src/pages/home/RecentActivity.js
import React from 'react';
import { Clock, User, Settings, Shield, FileText, Package, RefreshCw } from 'lucide-react';

const RecentActivity = () => {
  const activities = [
    {
      id: 1,
      type: 'user',
      icon: <User className="w-5 h-5" />,
      action: 'User Account Modified',
      description: 'Sarah Wilson was added to Admin group',
      user: 'John Doe',
      time: '5 minutes ago',
      color: 'text-blue-400'
    },
    {
      id: 2,
      type: 'system',
      icon: <Settings className="w-5 h-5" />,
      action: 'System Update',
      description: 'Security patches were installed automatically',
      user: 'System',
      time: '1 hour ago',
      color: 'text-purple-400'
    },
    {
      id: 3,
      type: 'permission',
      icon: <Shield className="w-5 h-5" />,
      action: 'Permissions Updated',
      description: 'Warehouse access granted to Logistics team',
      user: 'Admin',
      time: '2 hours ago',
      color: 'text-green-400'
    },
    {
      id: 4,
      type: 'document',
      icon: <FileText className="w-5 h-5" />,
      action: 'Document Updated',
      description: 'Q4 Logistics Report was modified',
      user: 'Mike Johnson',
      time: '3 hours ago',
      color: 'text-yellow-400'
    }
  ];

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Recent Activity</h2>
          <button className="flex items-center px-4 py-2 text-slate-400 hover:text-slate-300 gap-2">
            <RefreshCw className="w-4 h-4" />
            Refresh
          </button>
        </div>

        <div className="space-y-4">
          {activities.map((activity) => (
            <div key={activity.id} className="bg-slate-700 rounded-lg p-4">
              <div className="flex items-start">
                <div className={`p-2 rounded-full ${activity.color} bg-opacity-20`}>
                  {activity.icon}
                </div>
                <div className="ml-4 flex-1">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-slate-200 font-medium">{activity.action}</p>
                      <p className="text-slate-400 text-sm mt-1">{activity.description}</p>
                    </div>
                    <span className="text-sm text-slate-500">{activity.time}</span>
                  </div>
                  <div className="mt-2 flex items-center text-sm">
                    <User className="w-4 h-4 text-slate-400 mr-1" />
                    <span className="text-slate-400">{activity.user}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 flex justify-center">
          <button className="px-4 py-2 bg-slate-700 text-slate-300 rounded-lg hover:bg-slate-600 transition-colors">
            Load More
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecentActivity;