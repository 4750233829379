// src/pages/profile/Preferences.js
import React from 'react';
import { Bell, Moon, Globe, Clock, Mail, Smartphone, Eye, Save } from 'lucide-react';

const Preferences = () => {
  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-200">Preferences</h2>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors gap-2">
            <Save className="w-4 h-4" />
            Save Changes
          </button>
        </div>

        <div className="space-y-6">
          {/* Theme Preferences */}
          <div className="bg-slate-700 rounded-lg p-6">
            <h3 className="text-lg font-medium text-slate-200 mb-4">Theme & Display</h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Moon className="w-5 h-5 text-slate-400 mr-3" />
                  <span className="text-slate-200">Dark Mode</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div className="w-11 h-6 bg-slate-600 rounded-full peer peer-checked:after:translate-x-full 
                                peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 
                                after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 
                                after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Eye className="w-5 h-5 text-slate-400 mr-3" />
                  <span className="text-slate-200">Compact View</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div className="w-11 h-6 bg-slate-600 rounded-full peer peer-checked:after:translate-x-full 
                                peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 
                                after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 
                                after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>

          {/* Language & Region */}
          <div className="bg-slate-700 rounded-lg p-6">
            <h3 className="text-lg font-medium text-slate-200 mb-4">Language & Region</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm text-slate-400 mb-2">Language</label>
                <div className="relative">
                  <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                  <select className="w-full pl-10 pr-4 py-2 bg-slate-600 border border-slate-500 rounded-lg 
                                   text-slate-200 appearance-none focus:outline-none focus:ring-2 
                                   focus:ring-blue-500">
                    <option>English (US)</option>
                    <option>Spanish</option>
                    <option>French</option>
                  </select>
                </div>
              </div>
              <div>
                <label className="block text-sm text-slate-400 mb-2">Time Zone</label>
                <div className="relative">
                  <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                  <select className="w-full pl-10 pr-4 py-2 bg-slate-600 border border-slate-500 rounded-lg 
                                   text-slate-200 appearance-none focus:outline-none focus:ring-2 
                                   focus:ring-blue-500">
                    <option>Pacific Time (PT)</option>
                    <option>Eastern Time (ET)</option>
                    <option>Central Time (CT)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Notification Preferences */}
          <div className="bg-slate-700 rounded-lg p-6">
            <h3 className="text-lg font-medium text-slate-200 mb-4">Notifications</h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Bell className="w-5 h-5 text-slate-400 mr-3" />
                  <div>
                    <span className="text-slate-200">Push Notifications</span>
                    <p className="text-sm text-slate-400">Receive notifications for important updates</p>
                  </div>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" defaultChecked />
                  <div className="w-11 h-6 bg-slate-600 rounded-full peer peer-checked:after:translate-x-full 
                                peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 
                                after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 
                                after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Mail className="w-5 h-5 text-slate-400 mr-3" />
                  <div>
                    <span className="text-slate-200">Email Notifications</span>
                    <p className="text-sm text-slate-400">Get updates via email</p>
                  </div>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" defaultChecked />
                  <div className="w-11 h-6 bg-slate-600 rounded-full peer peer-checked:after:translate-x-full 
                                peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 
                                after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 
                                after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Smartphone className="w-5 h-5 text-slate-400 mr-3" />
                  <div>
                    <span className="text-slate-200">Mobile Alerts</span>
                    <p className="text-sm text-slate-400">Receive alerts on your mobile device</p>
                  </div>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div className="w-11 h-6 bg-slate-600 rounded-full peer peer-checked:after:translate-x-full 
                                peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 
                                after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 
                                after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preferences;