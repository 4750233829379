import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Search, UserPlus, MoreVertical, Shield, Mail, UserMinus, Edit2, RefreshCcw } from 'lucide-react';
import { listUsers } from '../../services/cognitoService';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';
import AddUserModal from './AddUserModal';

const AllUsers = () => {
  const { user, isAuthenticated } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showActionMenu, setShowActionMenu] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      // Check authentication first
      if (!isAuthenticated) {
        throw new Error('Not authenticated');
      }

      const data = await listUsers();
      if (Array.isArray(data)) {
        console.log('Fetched users successfully:', data.length);
        setUsers(data);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      let errorMessage = 'Failed to fetch users. Please try again.';
      
      if (error.message?.includes('credentials')) {
        errorMessage = 'Authentication error. Please try logging out and back in.';
      } else if (error.name === 'NotAuthorizedException') {
        errorMessage = 'Your session has expired. Please log in again.';
      } else if (error.name === 'AccessDeniedException') {
        errorMessage = 'You do not have permission to view users.';
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUsers();
    }
  }, [isAuthenticated]);

  const filteredUsers = users.filter(user => {
    const matchesSearch = user.Username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.Attributes.find(attr => attr.Name === 'email')?.Value.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  const handleActionClick = (userId) => {
    setShowActionMenu(showActionMenu === userId ? null : userId);
  };

  const handleRetry = () => {
    fetchUsers();
  };

  // Redirect if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="bg-slate-800 border border-slate-700 rounded-lg shadow-sm">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner />
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center p-8 space-y-4">
          <div className="text-red-400 text-center">
            {error}
          </div>
          <button 
            onClick={handleRetry}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                     hover:bg-blue-700 transition-colors gap-2"
          >
            <RefreshCcw className="w-4 h-4" />
            Retry
          </button>
        </div>
      ) : (
        <>
          {/* Search and Actions Bar */}
          <div className="p-4 border-b border-slate-700">
            <div className="flex items-center gap-4">
              <div className="flex-1 relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-4 h-4" />
                <input
                  type="text"
                  placeholder="Search users..."
                  className="w-full pl-10 pr-4 py-2 bg-slate-700 border border-slate-600 rounded-lg 
                           text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2 
                           focus:ring-blue-500 focus:border-transparent"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <select className="px-4 py-2 bg-slate-700 border border-slate-600 rounded-lg text-slate-200 
                               focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="all">All Users</option>
                <option value="active">Active</option>
                <option value="disabled">Disabled</option>
              </select>
              <button 
                onClick={() => setShowAddUserModal(true)}
                className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                          hover:bg-blue-700 transition-colors gap-2"
              >
                <UserPlus className="w-4 h-4" />
                Add User
              </button>
            </div>
          </div>

          {/* Users Grid */}
          <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredUsers.length === 0 ? (
                <div className="col-span-full text-center text-slate-400 py-8">
                  No users found
                </div>
              ) : (
                filteredUsers.map((user) => (
                  <div key={user.Username} 
                       className="bg-slate-700 rounded-lg overflow-hidden hover:bg-slate-600 transition-colors">
                    <div className="p-4">
                      <div className="flex items-start justify-between">
                        <div className="flex items-center">
                          <div className="h-10 w-10 rounded-full bg-slate-600 flex items-center justify-center">
                          <span className="text-slate-200 text-lg font-medium">
                              {`${user.Attributes.find(attr => attr.Name === 'given_name')?.Value.charAt(0).toUpperCase()}${user.Attributes.find(attr => attr.Name === 'family_name')?.Value.charAt(0).toUpperCase()}`}
                          </span>

                          </div>
                          <div className="ml-3">
                          <h3 className="text-slate-200 font-medium">
                            {user.Attributes.find(attr => attr.Name === 'given_name')?.Value} {user.Attributes.find(attr => attr.Name === 'family_name')?.Value}
                          </h3>
                            <p className="text-sm text-slate-400">
                              {user.Attributes.find(attr => attr.Name === 'email')?.Value}
                            </p>
                          </div>
                        </div>
                        
                        <div className="relative">
                          <button
                            onClick={() => handleActionClick(user.Username)}
                            className="p-1 text-slate-400 hover:text-slate-200 transition-colors"
                          >
                            <MoreVertical className="w-5 h-5" />
                          </button>
                          
                          {showActionMenu === user.Username && (
                            <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-slate-800 
                                          ring-1 ring-black ring-opacity-5 z-10">
                              <button className="flex items-center w-full px-4 py-2 text-sm text-slate-200 
                                               hover:bg-slate-700">
                                <Edit2 className="w-4 h-4 mr-3" />
                                Edit User
                              </button>
                              <button className="flex items-center w-full px-4 py-2 text-sm text-slate-200 
                                               hover:bg-slate-700">
                                <Shield className="w-4 h-4 mr-3" />
                                Manage Roles
                              </button>
                              <button className="flex items-center w-full px-4 py-2 text-sm text-slate-200 
                                               hover:bg-slate-700">
                                <Mail className="w-4 h-4 mr-3" />
                                Reset Password
                              </button>
                              <button className="flex items-center w-full px-4 py-2 text-sm text-red-400 
                                               hover:bg-slate-700">
                                <UserMinus className="w-4 h-4 mr-3" />
                                Disable User
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="px-4 py-3 bg-slate-800 border-t border-slate-600">
                      <div className="flex justify-between items-center">
                        <span className={`px-2 py-1 text-xs font-semibold rounded-full 
                          ${user.Enabled ? 'bg-green-900/50 text-green-400' : 'bg-red-900/50 text-red-400'}`}>
                          {user.Enabled ? 'Active' : 'Disabled'}
                        </span>
                        <span className="text-sm text-slate-400">
                          {new Date(user.UserCreateDate).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </>
      )}
      <AddUserModal 
        isOpen={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
        onSuccess={() => {
          fetchUsers(); // Refresh the user list
        }}
      />
   </div>
  );
};

export default AllUsers;